/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprovalSettings } from './ApprovalSettings';
import {
    ApprovalSettingsFromJSON,
    ApprovalSettingsFromJSONTyped,
    ApprovalSettingsToJSON,
} from './ApprovalSettings';
import type { NodeItemPriorityEnum } from './NodeItemPriorityEnum';
import {
    NodeItemPriorityEnumFromJSON,
    NodeItemPriorityEnumFromJSONTyped,
    NodeItemPriorityEnumToJSON,
} from './NodeItemPriorityEnum';
import type { NodeItemTypeEnum } from './NodeItemTypeEnum';
import {
    NodeItemTypeEnumFromJSON,
    NodeItemTypeEnumFromJSONTyped,
    NodeItemTypeEnumToJSON,
} from './NodeItemTypeEnum';
import type { NodeStatus } from './NodeStatus';
import {
    NodeStatusFromJSON,
    NodeStatusFromJSONTyped,
    NodeStatusToJSON,
} from './NodeStatus';

/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    nodeConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Node
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Node
     */
    roleIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    eSigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    changeable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    durationUnit?: NodeDurationUnitEnum;
    /**
     * 
     * @type {Date}
     * @memberof Node
     */
    dueDate?: Date;
    /**
     * 
     * @type {NodeStatus}
     * @memberof Node
     */
    state?: NodeStatus;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    nodeType?: string;
    /**
     * 
     * @type {NodeItemTypeEnum}
     * @memberof Node
     */
    nodeItemType?: NodeItemTypeEnum;
    /**
     * 
     * @type {NodeItemPriorityEnum}
     * @memberof Node
     */
    priority?: NodeItemPriorityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    restrictToSingleClaimant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    allowCollaboratorManagement?: boolean;
    /**
     * 
     * @type {ApprovalSettings}
     * @memberof Node
     */
    approvalSettings?: ApprovalSettings;
    /**
     * new field to allow backwards-compatible changes for nodes with multiple approval settings if there is a single approval setting on a node, approvalSettings will be set, null otherwise approvalSettingsList is always set with all approval settings or an empty list when no approval settings present

     * @type {Array<ApprovalSettings>}
     * @memberof Node
     */
    approvalSettingsList?: Array<ApprovalSettings>;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    requireRationale?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum NodeDurationUnitEnum {
    Hours = 'HOURS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS'
}

export function NodeFromJSON(json: any): Node {
    return NodeFromJSONTyped(json, false);
}

export function NodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Node {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nodeConfigurationId': !exists(json, 'nodeConfigurationId') ? undefined : json['nodeConfigurationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'roleIds': !exists(json, 'roleIds') ? undefined : (json['roleIds'] as Array<string>),
        'eSigRequired': !exists(json, 'eSigRequired') ? undefined : json['eSigRequired'],
        'changeable': !exists(json, 'changeable') ? undefined : json['changeable'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
        'dueDate': !exists(json, 'dueDate') || json['dueDate'] === '' ? undefined : new Date(json['dueDate']),
        'state': !exists(json, 'state') ? undefined : NodeStatusFromJSON(json['state']),
        'nodeType': !exists(json, 'nodeType') ? undefined : json['nodeType'],
        'nodeItemType': !exists(json, 'nodeItemType') ? undefined : NodeItemTypeEnumFromJSON(json['nodeItemType']),
        'priority': !exists(json, 'priority') ? undefined : NodeItemPriorityEnumFromJSON(json['priority']),
        'restrictToSingleClaimant': !exists(json, 'restrictToSingleClaimant') ? undefined : json['restrictToSingleClaimant'],
        'allowCollaboratorManagement': !exists(json, 'allowCollaboratorManagement') ? undefined : json['allowCollaboratorManagement'],
        'approvalSettings': !exists(json, 'approvalSettings') ? undefined : ApprovalSettingsFromJSON(json['approvalSettings']),
        'approvalSettingsList': !exists(json, 'approvalSettingsList') ? undefined : (json['approvalSettingsList'] as Array<ApprovalSettings>).map(ApprovalSettingsFromJSON),
        'requireRationale': !exists(json, 'requireRationale') ? undefined : json['requireRationale'],
    };
}

export function NodeToJSON(value?: Node | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nodeConfigurationId': value.nodeConfigurationId,
        'name': value.name,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'roleIds': value.roleIds === undefined ? undefined : (value.roleIds as Array<string>),
        'eSigRequired': value.eSigRequired,
        'changeable': value.changeable,
        'duration': value.duration,
        'durationUnit': value.durationUnit,
        'dueDate': value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
        'state': NodeStatusToJSON(value.state),
        'nodeType': value.nodeType,
        'nodeItemType': NodeItemTypeEnumToJSON(value.nodeItemType),
        'priority': NodeItemPriorityEnumToJSON(value.priority),
        'restrictToSingleClaimant': value.restrictToSingleClaimant,
        'allowCollaboratorManagement': value.allowCollaboratorManagement,
        'approvalSettings': ApprovalSettingsToJSON(value.approvalSettings),
        'approvalSettingsList': value.approvalSettingsList === undefined ? undefined : (value.approvalSettingsList as Array<ApprovalSettings>).map(ApprovalSettingsToJSON),
        'requireRationale': value.requireRationale,
    };
}

