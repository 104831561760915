/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * Workflow Layout is represented as a key value pair where the key is a Node ID and the value is a list of Node Ids that are connected to the key Node
 * @export
 * @interface GetWorkflowLayoutResponse
 */
export interface GetWorkflowLayoutResponse {
    /**
     * 
     * @type {Workflow}
     * @memberof GetWorkflowLayoutResponse
     */
    workflow?: Workflow;
    /**
     * 
     * @type {Array<Node>}
     * @memberof GetWorkflowLayoutResponse
     */
    nodes?: Array<Node>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetWorkflowLayoutResponse
     */
    nodeLayout?: { [key: string]: Array<string>; };
}

export function GetWorkflowLayoutResponseFromJSON(json: any): GetWorkflowLayoutResponse {
    return GetWorkflowLayoutResponseFromJSONTyped(json, false);
}

export function GetWorkflowLayoutResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWorkflowLayoutResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowFromJSON(json['workflow']),
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<Node>).map(NodeFromJSON),
        'nodeLayout': !exists(json, 'nodeLayout') ? undefined : json['nodeLayout'],
    };
}

export function GetWorkflowLayoutResponseToJSON(value?: GetWorkflowLayoutResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow': WorkflowToJSON(value.workflow),
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<Node>).map(NodeToJSON),
        'nodeLayout': value.nodeLayout,
    };
}

