/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprovalSettings } from './ApprovalSettings';
import {
    ApprovalSettingsFromJSON,
    ApprovalSettingsFromJSONTyped,
    ApprovalSettingsToJSON,
} from './ApprovalSettings';
import type { NodeItemPriorityEnum } from './NodeItemPriorityEnum';
import {
    NodeItemPriorityEnumFromJSON,
    NodeItemPriorityEnumFromJSONTyped,
    NodeItemPriorityEnumToJSON,
} from './NodeItemPriorityEnum';
import type { NodeItemTypeEnum } from './NodeItemTypeEnum';
import {
    NodeItemTypeEnumFromJSON,
    NodeItemTypeEnumFromJSONTyped,
    NodeItemTypeEnumToJSON,
} from './NodeItemTypeEnum';

/**
 * 
 * @export
 * @interface ExecutionPlanNode
 */
export interface ExecutionPlanNode {
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    nodeConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    type?: ExecutionPlanNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    executionPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    executionPlanStepCompletionType?: ExecutionPlanNodeExecutionPlanStepCompletionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionPlanNode
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionPlanNode
     */
    ruleIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanNode
     */
    eSigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanNode
     */
    changeable?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionPlanNode
     */
    roleIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ExecutionPlanNode
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    durationUnit?: ExecutionPlanNodeDurationUnitEnum;
    /**
     * 
     * @type {Date}
     * @memberof ExecutionPlanNode
     */
    dueDate?: Date;
    /**
     * 
     * @type {NodeItemTypeEnum}
     * @memberof ExecutionPlanNode
     */
    nodeItemType?: NodeItemTypeEnum;
    /**
     * 
     * @type {NodeItemPriorityEnum}
     * @memberof ExecutionPlanNode
     */
    priority?: NodeItemPriorityEnum;
    /**
     * 
     * @type {number}
     * @memberof ExecutionPlanNode
     */
    pathIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanNode
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanNode
     */
    restrictToSingleClaimant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanNode
     */
    allowCollaboratorManagement?: boolean;
    /**
     * 
     * @type {ApprovalSettings}
     * @memberof ExecutionPlanNode
     */
    approvalSettings?: ApprovalSettings;
    /**
     * new field to allow backwards-compatible changes for nodes with multiple approval settings if there is a single approval setting on a node, approvalSettings will be set, null otherwise approvalSettingsList is always set with all approval settings or an empty list when no approval settings present

     * @type {Array<ApprovalSettings>}
     * @memberof ExecutionPlanNode
     */
    approvalSettingsList?: Array<ApprovalSettings>;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanNode
     */
    requireRationale?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ExecutionPlanNodeTypeEnum {
    Split = 'SPLIT',
    Join = 'JOIN',
    Step = 'STEP'
}/**
* @export
* @enum {string}
*/
export enum ExecutionPlanNodeExecutionPlanStepCompletionTypeEnum {
    One = 'ONE',
    Majority = 'MAJORITY',
    All = 'ALL',
    Invalid = 'INVALID'
}/**
* @export
* @enum {string}
*/
export enum ExecutionPlanNodeDurationUnitEnum {
    Hours = 'HOURS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS'
}

export function ExecutionPlanNodeFromJSON(json: any): ExecutionPlanNode {
    return ExecutionPlanNodeFromJSONTyped(json, false);
}

export function ExecutionPlanNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionPlanNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nodeConfigurationId': !exists(json, 'nodeConfigurationId') ? undefined : json['nodeConfigurationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'executionPlanId': !exists(json, 'executionPlanId') ? undefined : json['executionPlanId'],
        'executionPlanStepCompletionType': !exists(json, 'executionPlanStepCompletionType') ? undefined : json['executionPlanStepCompletionType'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'ruleIds': !exists(json, 'ruleIds') ? undefined : (json['ruleIds'] as Array<string>),
        'eSigRequired': !exists(json, 'eSigRequired') ? undefined : json['eSigRequired'],
        'changeable': !exists(json, 'changeable') ? undefined : json['changeable'],
        'roleIds': !exists(json, 'roleIds') ? undefined : (json['roleIds'] as Array<string>),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
        'dueDate': !exists(json, 'dueDate') || json['dueDate'] === '' ? undefined : new Date(json['dueDate']),
        'nodeItemType': !exists(json, 'nodeItemType') ? undefined : NodeItemTypeEnumFromJSON(json['nodeItemType']),
        'priority': !exists(json, 'priority') ? undefined : NodeItemPriorityEnumFromJSON(json['priority']),
        'pathIndex': !exists(json, 'pathIndex') ? undefined : json['pathIndex'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'restrictToSingleClaimant': !exists(json, 'restrictToSingleClaimant') ? undefined : json['restrictToSingleClaimant'],
        'allowCollaboratorManagement': !exists(json, 'allowCollaboratorManagement') ? undefined : json['allowCollaboratorManagement'],
        'approvalSettings': !exists(json, 'approvalSettings') ? undefined : ApprovalSettingsFromJSON(json['approvalSettings']),
        'approvalSettingsList': !exists(json, 'approvalSettingsList') ? undefined : (json['approvalSettingsList'] as Array<ApprovalSettings>).map(ApprovalSettingsFromJSON),
        'requireRationale': !exists(json, 'requireRationale') ? undefined : json['requireRationale'],
    };
}

export function ExecutionPlanNodeToJSON(value?: ExecutionPlanNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nodeConfigurationId': value.nodeConfigurationId,
        'name': value.name,
        'type': value.type,
        'executionPlanId': value.executionPlanId,
        'executionPlanStepCompletionType': value.executionPlanStepCompletionType,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'ruleIds': value.ruleIds === undefined ? undefined : (value.ruleIds as Array<string>),
        'eSigRequired': value.eSigRequired,
        'changeable': value.changeable,
        'roleIds': value.roleIds === undefined ? undefined : (value.roleIds as Array<string>),
        'duration': value.duration,
        'durationUnit': value.durationUnit,
        'dueDate': value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
        'nodeItemType': NodeItemTypeEnumToJSON(value.nodeItemType),
        'priority': NodeItemPriorityEnumToJSON(value.priority),
        'pathIndex': value.pathIndex,
        'description': value.description,
        'restrictToSingleClaimant': value.restrictToSingleClaimant,
        'allowCollaboratorManagement': value.allowCollaboratorManagement,
        'approvalSettings': ApprovalSettingsToJSON(value.approvalSettings),
        'approvalSettingsList': value.approvalSettingsList === undefined ? undefined : (value.approvalSettingsList as Array<ApprovalSettings>).map(ApprovalSettingsToJSON),
        'requireRationale': value.requireRationale,
    };
}

